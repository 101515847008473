import React from 'react';

import Head from '../components/Head';

import '../style/_common.scss';
import StandardPage from '../components/StandardPage';
import { Button } from 'antd';

class Home extends React.Component {
  render() {
    // const { email } = this.state;
    return (
      <StandardPage>
        <Head
          title="Bagel Waitlist Joined!"
          description="Bagel is an all-in-one software platform for direct
          primary care practices. If you're looking for a physician-friendly EHR, a powerful billing 
          platform, a flexible scheduling tool, an e-prescribing system, or all of the above, then Bagel is for you."
          url="/joined"
          image="/og_image.jpg"
        />

        <div className="fullpage wide col ac jc tac">
          <div className="readable">
            <h2 style={{ padding: '0px', lineHeight: 1 }}>You've joined the waitlist</h2>
            <p style={{ padding: '10px 0px', opacity: 0.7 }}>
              And qualified for $300 of early bird Bagel credit!
            </p>
            <p style={{ padding: '15px 0px', opacity: 0.8 }}>
              We're currently in the early stages of beta testing Bagel. Once Bagel is ready for you
              to try, we'll reach out with instructions on how to create an account, redeem your
              Bagel credit, and optionally schedule a demo with our team.
            </p>
            <br />
            <Button size="large" type="primary" href="/">
              Back to homepage
            </Button>
          </div>
        </div>
      </StandardPage>
    );
  }
}

export default Home;
